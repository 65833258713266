import React from "react";
import styled from "@emotion/styled";

export default function TextMarkerVertical({ text, currentZoom, style, ...props }) {
  return <Wrapper>{text}</Wrapper>;
}
const Wrapper = styled("div")`
  // transform: rotate(-0.25turn);
  transform: rotate(-0.25turn) translate(0, -102px);
  transform-origin: bottom right;
  position: absolute;
  // transform: 'translate(-50%, -50%)'
  cursor: grab;
  width: max-content;
  text-align: center;
  vertical-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
`;
