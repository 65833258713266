import React, { useEffect, useState } from "react";

import Helmet from "react-helmet";
import styled from "@emotion/styled";
import { useParams } from "react-router";

import { Container, Grid, Typography, Box, Button, ButtonGroup, Link } from "@mui/material";
import LeftArrow from "../LeftArrow";
import RightArrow from "../RightArrow";

import Gallery from "../Gallery";

export default function PropertyDetail({
  propertyDetail,
  setPropertyId,
  propertyId,
  isSmall,
  ...props
}) {
  const [isFullScreen, setIsFullScreen] = useState();
  const { id, slug } = useParams();

  useEffect(() => {
    setPropertyId(() => slug);
  }, [slug]);

  const handleOnScreenChange = fullScreen => {
    setIsFullScreen(() => fullScreen);
    return fullScreen;
  };

  const ImageWrapper = styled.div`
    position: relative;
    background-image: url(${props => props.src});
    background-size: ${isFullScreen ? "contain" : "cover"};
    background-repeat: no-repeat;
    background-position: center;
    height: ${isFullScreen ? "70vh" : "235px"};

    ${props => props.theme.breakpoints.up("sm")} {
      height: ${isFullScreen ? "100vh" : "352px"};
    }
    ${props => props.theme.breakpoints.up("md")} {
      height: ${isFullScreen ? "90vh" : "470px"};
    }
    ${props => props.theme.breakpoints.up("md")} {
      height: ${isFullScreen ? "90vh" : "528px"};
    }
  `;

  const itemRenderer = item => {
    const isNoCaption = item?.description === "No Caption";

    return (
      <div>
        <ImageWrapper
          className="print"
          description={item.description}
          fullscreen={item.fullscreen}
          // handleImageLoaded={this.handleImageLoaded}
          src={item.original}
          alt={item.originalAlt}
          originalHeight={item.originalHeight}
          originalWidth={item.originalWidth}
          originalTitle={item.originalTitle}
          sizes={item.sizes}
          loading={item.loading}
          srcSet={item.srcSet}
        />
        {!isNoCaption && <Caption>{item.description}</Caption>}
      </div>
    );
  };

  let pageTitle = slug.replace(/(?:^|-)([a-z])/g, (match, group) => ` ${group.toUpperCase()}`);

  pageTitle = pageTitle.replace(/-/g, " ");

  return (
    <Box component={Container} maxWidth="lg" disableGutters sx={{ mb: { xs: "-4rem", sm: 0 } }}>
      {/* <Box>
        <Typography>Logo</Typography>
      </Box> */}
      <Helmet>
        <title itemProp="name" lang="en">
          {pageTitle}
        </title>
      </Helmet>
      <Box
        component={Grid}
        container
        justifyContent={"space-evenly"}
        pb={{ xs: "2rem", md: "5rem" }}
      >
        <Box
          component={Grid}
          item
          xs={12}
          sx={{ width: "100%", height: "max-content", overflowY: "scroll" }}
          m={{ xs: 0, md: 5 }}
          mb={{ xs: "2rem", md: "5rem" }}
        >
          {/* TODO Move the settings to the CMS options json??? */}
          {propertyDetail?.photo?.data?.attributes?.Images?.data && (
            <Gallery
              options={{
                data: propertyDetail?.photo?.data?.attributes?.Images?.data,
                settings: {
                  showThumbnails: false,
                  // thumbnailPosition: isPhone ? "bottom" : "left",
                  showBullets: false,
                  showIndex: false,
                  showPlayButton: true,
                  autoPlay: false,
                  renderItem: itemRenderer,
                  onScreenChange: handleOnScreenChange,
                  showNav: isSmall ? false : true,
                  // onSlide: handleOnSlide,
                  renderLeftNav: (onClick, disabled) => {
                    return <LeftArrow onClick={onClick} disabled={disabled} />;
                  },
                  renderRightNav: (onClick, disabled) => {
                    return <RightArrow onClick={onClick} disabled={disabled} />;
                  },
                },
              }}
            />
          )}
        </Box>
        <Grid item xs={12} md={8} p={{ xs: "0 1rem" }}>
          <Typography variant="h2" align="left">
            {propertyDetail?.Title}
          </Typography>
          <Box
            // style={{ ...textBlockStyle }}
            // sx={{ ...textBlockStyle }}
            // {...layout}
            // component={component === "Grid" ? Grid : component ? component : null}
            // container={component === "Grid" ? true : false}
            // item={component === "Grid" ? true : false}
            textAlign={"left"}
            sx={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: propertyDetail?.Description }}
          />
          <Box
            mt={"2rem"}
            component={Grid}
            direction="column"
            container
            justifyContent={"flex-start"}
            alignContent={"flex-start"}
          >
            {propertyDetail?.sales_contacts?.data.length > 0 && (
              <Typography
                variant="h4"
                align="left"
                sx={{ fontWeight: 800 }}
                color={"primary.light"}
                width="100%"
              >
                For Leasing Inquiries contact:
              </Typography>
            )}
            {propertyDetail?.sales_contacts?.data.length > 0 &&
              propertyDetail?.sales_contacts?.data.map((contact, index) => {
                const { Name, Email } = contact.attributes;

                return (
                  <Box
                    key={Name}
                    component={Grid}
                    container
                    justifyContent={"flex-start"}
                    mt={index !== 0 ? "1rem" : null}
                  >
                    <Typography variant="body1" align="left" sx={{ width: "100%" }}>
                      {Name}
                    </Typography>
                    <Typography
                      variant="body1"
                      component={Link}
                      href={`mailto:${Email}`}
                      align="left"
                      underline="none"
                      color={"primary.light"}
                      sx={{ width: "100%" }}
                    >
                      {Email}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        </Grid>
        {(propertyDetail?.pdfs?.data[0]?.attributes?.File?.data?.attributes?.url ||
          propertyDetail?.Website) && (
          <Grid item xs={12} md={3} p={{ xs: "calc(3.5rem - 4px) 1rem 0" }}>
            <ButtonGroup
              variant="contained"
              orientation="vertical"
              disableElevation
              fullWidth={isSmall ? true : false}
              size="large"
            >
              {propertyDetail?.pdfs?.data[0]?.attributes?.File?.data?.attributes?.url && (
                <Button
                  href={propertyDetail?.pdfs.data[0].attributes.File.data.attributes.url}
                  sx={{ mb: 0.5, mt: 0.5 }}
                  target="Clearwater"
                >
                  Download PDF
                </Button>
              )}
              {propertyDetail?.Website && (
                <Button
                  href={propertyDetail?.Website}
                  sx={{ mt: 0.5, height: 65 }}
                  target="Clearwater"
                >
                  Website
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        )}
        {!propertyDetail?.pdfs?.data[0]?.attributes?.File?.data?.attributes?.url &&
          !propertyDetail?.Website && (
            <Grid item xs={12} md={3} p={{ xs: "3.5rem 1rem 0" }}>
              <Typography>No Website or PDF</Typography>
            </Grid>
          )}
      </Box>
      {/* Details section */}
      <Box
        component={Grid}
        container
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        alignContent={"flex-start"}
        sx={{ backgroundColor: "#f7f7f7" }}
        p={{ xs: "2rem 1rem", md: "5rem 3rem" }}
      >
        {propertyDetail?.Details &&
          Object?.keys(propertyDetail?.Details)?.map((key, index) => {
            let useMarginTop = false;
            if (isSmall) {
              useMarginTop = index !== 0;
            }
            return (
              <Box
                key={index}
                component={Grid}
                item
                container
                direction={"column"}
                alignContent={"flex-start"}
                justifyContent={"flex-start"}
                xs={12}
                md={3}
              >
                {propertyDetail?.Details[key]?.map((detail, index) => {
                  useMarginTop = useMarginTop ? useMarginTop : index !== 0;

                  const NAME = Object.keys(detail)[0];
                  const VALUE = detail[Object.keys(detail)[0]];

                  const RETURN_VALUE = (
                    <Box
                      key={NAME}
                      component={Typography}
                      variant="body1"
                      align="left"
                      sx={{
                        whiteSpace: "pre-wrap",
                        mt: useMarginTop ? "1rem" : null,
                        width: "100%",
                      }}
                    >
                      <span style={{ fontWeight: 600, textTransform: "uppercase" }}>{NAME}</span>
                      <br />
                      {VALUE}
                    </Box>
                  );

                  return RETURN_VALUE;
                })}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}
const Wrapper = styled(Container)``;
const Caption = styled.div`
  background-color: rgb(0, 0, 0, 0.5);
  text-transform: capitalize;
  z-index: 99;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-shadow: 1px 1px black;
  height: fit-content;
  padding: 20px 20px;
  line-height: 1;
`;
