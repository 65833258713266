import React from "react";
import styled from "@emotion/styled";
import { Box, Container, Grid, Typography } from "@mui/material";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import StrapiGrid from "./StrapiGrid";

import SportCourtIcon from "../assets/SportCourtIcon.svg";
import KitchenIcon from "../assets/KitchenIcon.svg";
import PlayAreaIcon from "../assets/PlayAreaIcon.svg";
import PlazaIcon from "../assets/PlazaIcon.svg";
import RinkIcon from "../assets/RinkIcon.svg";
import PoolAndFitnessIcon from "../assets/PoolAndFitnessIcon.svg";
import MeetingAndFunctionIcon from "../assets/MeetingAndFunctionIcon.svg";
import SizeIcon from "../assets/SizeIcon.svg";

import Wave from "../assets/WaveLighterBlue.svg";

export default function StyleIcons({ isSmall, isXsmall, ...props }) {
  const images = [
    {
      description: `18,000 sq. Ft. 
    community clubhouse`,
      original: SizeIcon,
    },
    {
      description: `Meeting & function
    facilities`,
      original: MeetingAndFunctionIcon,
    },
    {
      description: `Indoor pool & 
    fitness facility`,
      original: PoolAndFitnessIcon,
    },
    {
      description: `Multi-surface
    outdoor ice rink`,
      original: RinkIcon,
    },
    {
      description: `Plaza for private &
     outdoor events`,
      original: PlazaIcon,
    },
    { description: `Kids’ play area`, original: PlayAreaIcon },
    { description: `Community Kitchen`, original: KitchenIcon },
    { description: `Sport court`, original: SportCourtIcon },
  ];

  const itemRenderer = item => {
    const isNoCaption = item?.description === "No Caption";

    return (
      <div>
        <HouseIcons src={item.original} alt={item.original} />
        {!isNoCaption && (
          <Caption component="div" variant="button">
            {item.description}
          </Caption>
        )}
      </div>
    );
  };

  const options = {
    settings: {
      showThumbnails: false,
      // thumbnailPosition: isPhone ? "bottom" : "left",
      showBullets: false,
      showIndex: false,
      showPlayButton: false,
      showFullscreenButton: false,
      autoPlay: true,
      renderItem: itemRenderer,
      showNav: isSmall ? false : true,
      slideInterval: 3000,
      slideDuration: 200,
      // onSlide: handleOnSlide,
      //   renderLeftNav: (onClick, disabled) => {
      //     return <LeftArrow onClick={onClick} disabled={disabled} />;
      //   },
      //   renderRightNav: (onClick, disabled) => {
      //     return <RightArrow onClick={onClick} disabled={disabled} />;
      //   },
    },
  };

  return (
    <Wrapper component={Box} sx={{ ...props?.options?.wrapperStyle?.sx }} maxWidth="xl">
      {(isSmall || isXsmall) && <ImageGallery items={images} {...options.settings} {...props} />}
      {!isSmall && !isXsmall && (
        <StrapiGrid options={props?.options?.layout}>
          {images.map((item, index, array) => {
            const isNoCaption = item?.description === "No Caption";
            return (
              <Grid
                key={item.original}
                item
                container
                md={3}
                xs={12}
                direction="column"
                justifyContent={"flex-start"}
                alignContent={"center"}
                sx={{ mt: { xs: "4rem", md: "2rem" } }}
              >
                <HouseIcons src={item.original} alt={item.original} />
                {!isNoCaption && (
                  <Caption variant="button" align="center">
                    {item.description}
                  </Caption>
                )}
              </Grid>
            );
          })}
        </StrapiGrid>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(Container)`
  min-height: 375px;
  background: url(${Wave}) top center/cover no-repeat;
`;

const HouseIcons = styled("img")`
  max-width: 195px;
  height: 195px;
  //   height: auto;
`;

const Caption = styled(Typography)`
  white-space-collapse: preserve-breaks;
  text-transform: uppercase;
  z-index: 99;
  color: white;
  text-align: center;
  padding-top: 3rem;
  height: fit-content;
`;
