import React, { useState } from "react";
import styled from "@emotion/styled";

import { Box, Tab, Tabs, Container, Grid, Typography } from "@mui/material";
import { parseWithLinks } from "../Functions";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import SwipeableViews from "react-swipeable-views";

export default function HouseType({ imageStyle, isSmall, isXsmall, ...props }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`style-tabpanel-${index}`}
        aria-labelledby={`style-tab-${index}`}
        {...other}
      >
        {/* {value === index && <Box sx={{ p: 3 }}>{children}</Box>} */}
        {value === index && <>{children}</>}
      </Box>
    );
  }

  return (
    <Wrapper sx={{ ...props?.options?.wrapperStyle }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant={isXsmall ? "scrollable" : "fullWidth"}
        indicatorColor="secondary"
        TabIndicatorProps={{ sx: { border: "white", backgroundColor: "white" } }}
        textColor="inherit"
        scrollButtons
        // allowScrollButtonsMobile
        centered={isXsmall ? false : true}
        aria-label="Housing style tabs"
      >
        {props?.Type?.map((type, index) => {
          return <Tab key={type?.Name} label={type?.Name} />;
        })}
      </Tabs>
      <SwipeableViews index={value} onChangeIndex={handleChange}>
        {props?.Type?.map((type, index) => {
          return (
            <CustomTabPanel
              key={type?.Name}
              value={value}
              index={index}
              sx={{ paddingTop: { xs: "1em", md: "4em" } }}
            >
              <Box component={Grid} container>
                <Grid
                  item
                  container
                  direction={"column"}
                  justifyContent={"stretch"}
                  xs={12}
                  sm={6}
                  sx={{
                    padding: {
                      xs: "1em 0 1em",
                      md: "4em 2em",
                      ".MuiTypography-body1": {
                        color: "#fff",
                      },
                    },
                    img: { padding: "1em" },
                  }}
                >
                  {parseWithLinks(type?.Description)}
                  <Box
                    component={Grid}
                    container
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    alignContent={"flex-start"}
                  >
                    {type.home_builders?.data?.map((builder, index) => {
                      if (!builder?.attributes?.Logo?.data[0]) {
                        return (
                          <Typography color={"white"} variant="body1">
                            {builder?.attributes?.Name}
                          </Typography>
                        );
                      }
                      return (
                        <img
                          key={builder?.attributes?.Logo?.data[0]?.attributes?.url}
                          src={builder?.attributes?.Logo?.data[0]?.attributes?.url}
                          alt={builder?.attributes?.Logo?.data[0]?.attributes?.alternativeText}
                        />
                      );
                    })}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <SimpleResponsivePicture
                    imageObj={type?.Image?.data?.attributes || type?.Image?.data?.attributes?.url}
                    sx={{ width: { xs: "95%", md: 459 }, height: { xs: "auto", md: 329 } }}
                  />
                  <SimpleResponsivePicture
                    imageObj={
                      type?.StreetSketch.data.attributes || type?.StreetSketch.data.attributes
                    }
                    sx={{ width: { xs: "95%", md: 448 }, height: "auto", paddingTop: "2em" }}
                  />
                </Grid>
              </Box>
            </CustomTabPanel>
          );
        })}
      </SwipeableViews>
    </Wrapper>
  );
}
const Wrapper = styled(Box)`
  //
`;
