import React from "react";
import styled from "@emotion/styled";
import { Typography, Grid } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SimpleResponsivePicture from "./SimpleResponsivePicture";

export default function Testimonials({ Testimonial: testimonialData, ...props }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    // variableWidth: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    easing: true,
    arrows: props?.isSmall ? false : true,
    ...props?.options?.sliderOptions,
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      style={{ backgroundColor: props.theme.palette.primary.main }}
    >
      <Grid item xs={12} md={4}>
        <SimpleResponsivePicture
          imageObj={props?.image?.data?.attributes || props?.image?.data?.attributes?.url}
          sx={{ ...props?.options?.imageStyle }}
        />
      </Grid>
      <Grid item xs={12} md={8} align="center" sx={{ padding: { xs: "2rem 2rem", md: "0 4rem" } }}>
        <Grid container direction={"column"}>
          <Typography variant="h3" color="white">
            TESTIMONIALS
          </Typography>
          {/* Set the minHeight if the slider pushes the Headline up and down with changing line counts */}
          <StyledSlider {...settings}>
            {testimonialData?.map((testimonial, index) => {
              const quote = testimonial?.Quote;
              const name = testimonial?.Name;
              return (
                <Typography key={quote} align="center" color={"white"}>
                  {quote}
                  <br />– {name}
                </Typography>
              );
            })}
          </StyledSlider>
        </Grid>
      </Grid>
    </Grid>
  );
}

const StyledSlider = styled(Slider)`
  position: relative;
  min-height: 110px;
  max-width: 883px;

  width: 100%;
  ${props => props.theme.breakpoints.up("md")} {
    padding: 0 4rem;
  }
`;
