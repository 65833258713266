import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

const font = "'Montserrat', sans-serif";
const titleFont = "'brigade',sans-serif";

const defaultTheme = responsiveFontSizes(
  createMuiTheme({
    maxWidth: "1920px",
    activeColor: "#6098AF",
    palette: {
      primary: {
        main2: "rgba(37,95,126,1)",
        main: "rgba(16,72,100,1)",
        dark: "rgba(11, 31, 44, 1)",
        red: "rgba(150, 56, 57, 1)",
        light: "rgba(192, 202, 200, 1)",
        // active: "rgba(192, 202, 200, 0.54)", //Toggled
        hover: "rgba(168, 53, 58, 1)",
        selected: "rgba(192, 202, 200, 0.54)",
        contrastText: "#fff",
        footer: "rgb(3,44,67)",
        bullet: "rgba(192, 202, 200,1)",
      },
      secondary: {
        dark: "#BDC93A", //D Green
        main: "#466566", //L Green
        light: "rgba(248, 247, 244)",
        highlight: "#E6781F", //Orange
        lightlight: "rgba(233,242,240,1)", //lightlightblue
        hover: "rgba(10,40,60,1)",
      },
      common: {
        black: "rgba(87,87,87,1)",
        lightBlack: "#1b1c1e",
      },
      action: {
        //This is a reference. You can use theme.palette.action.active etc.
        active: "rgba(192, 202, 200, 0.54)",
        hover: "rgba(37,95,126,1)",
        hoverOpacity: 1,
        selected: "rgba(192, 202, 200, 0.54)",
        selectedOpacity: 0.08,
        disabled: "rgba(0, 0, 0, 0.26)",
        disabledBackground: "rgba(0, 0, 0, 0.12)",
        disabledOpacity: 0.38,
        focus: "rgba(0, 0, 0, 0.12)",
        focusOpacity: 0.12,
        activatedOpacity: 0.12,
      },
      text: {
        primary: "rgba(87, 87, 87, 1)",
        secondary: "rgba(0, 0, 0, 0.6)",
        disabled: "rgba(0, 0, 0, 0.38)",
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: font,
      // fontSize: 12,
    },
  })
);

export const theme = responsiveFontSizes(
  createMuiTheme({
    ...defaultTheme,
    components: {
      // Style sheet name
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              background: "white",
              color: defaultTheme.palette.primary.main,
            },
            fontWeight: 600,
            border: "1px solid white",
          },
        },
      },
      MuiIconButton: {
        //this will change the background of the button
        styleOverrides: {
          colorPrimary: {
            "&:hover": {
              backgroundColor: defaultTheme.palette.primary.hover,
              color: defaultTheme.palette.common.white,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // syntax for hover
          // root: {
          //   "&:hover": {
          //     backgroundColor: defaultTheme.palette.common.white,
          //   },
          // },

          // Name of the rule

          label: {
            // Some CSS
            fontSize: "1rem",
            fontFamily: titleFont,
            textTransform: "uppercase",

            // "&:hover": {
            //   color: defaultTheme.palette.primary.light,
            // },
          },
          root: {
            letterSpacing: "1.96px",
            fontSize: ".875rem",
            fontWeight: 600,
            borderRadius: 30,
            padding: "1em 2em",
          },
          // contained: {
          //   letterSpacing: "1.96px",
          //   fontSize: ".875rem",
          //   fontWeight: 600,
          //   borderRadius: 30,
          // },

          containedPrimary: {
            backgroundColor: defaultTheme.palette.primary.main,
            color: defaultTheme.palette.primary.contrastText,

            "&:hover": {
              backgroundColor: defaultTheme.palette.primary.red,
            },
            "&:visited": {
              backgroundColor: defaultTheme.palette.primary.active,
            },
          },
          containedSecondary: {
            backgroundColor: defaultTheme.palette.secondary.lightlight,
            color: defaultTheme.palette.secondary.hover,

            "&:hover": {
              color: defaultTheme.palette.secondary.lightlight,
              backgroundColor: defaultTheme.palette.secondary.hover,
            },
            "&:visited": {
              backgroundColor: defaultTheme.palette.primary.active,
            },
          },
          outlinedPrimary: {
            color: defaultTheme.palette.primary.main,
            backgroundColor: defaultTheme.palette.common.white,
            "&:hover": {
              color: defaultTheme.palette.primary.contrastText,
              backgroundColor: defaultTheme.palette.primary.active,
            },
          },
          outlinedSecondary: {
            color: defaultTheme.palette.secondary.main,

            "&:hover": {
              color: defaultTheme.palette.primary.contrastText,
              backgroundColor: defaultTheme.palette.primary.active,
            },
          },
          text: {
            letterSpacing: ".52px",
            fontSize: ".875rem",
            fontWeight: 500,
            borderRadius: 4,
            padding: "1em 2em",
          },
          textPrimary: {
            "&:visited": { color: defaultTheme.palette.primary.dark },
            color: defaultTheme.palette.primary.dark,
            "&:hover": {
              color: defaultTheme.palette.primary.red,
              backgroundColor: "transparent",
            },
          },
          textSecondary: {
            "&:hover": {
              color: defaultTheme.palette.primary.red,
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: "#000",
            // fontSize: ".5rem",
            // height: "1.25rem",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&:hover $notchedOutline": {
              borderColor: defaultTheme.palette.primary.main,
            },
          },
          input: {},
          notchedOutline: { borderColor: defaultTheme.palette.primary.light },
        },
      },
      // MuiInputLabel: {
      //   styleOverrides: {
      //     root: {
      //       color: "#fff",
      //     },
      //   },
      // },

      MuiTypography: {
        // fontSize: 12,
        styleOverrides: {
          h1: {
            fontSize: "2.1875rem",
            fontFamily: titleFont,
            fontWeight: 500,
            textTransform: "capitalize",
            [defaultTheme.breakpoints.up("sm")]: {
              fontSize: "2.5rem",
            },
            [defaultTheme.breakpoints.up("md")]: {
              fontSize: "3.0rem",
            },
            [defaultTheme.breakpoints.up("lg")]: {
              fontSize: "4.375rem",
            },
            [defaultTheme.breakpoints.up("xl")]: {
              fontSize: "4.375rem",
            },
          },
          h2: {
            fontFamily: titleFont,
            fontWeight: 700,
            marginBottom: "1rem",
            color: defaultTheme.palette.primary.red,
            [defaultTheme.breakpoints.up("sm")]: {
              fontSize: "2rem",
              marginBottom: "1rem",
            },
            [defaultTheme.breakpoints.up("md")]: {
              fontSize: "2.5rem",
              marginBottom: "1.4rem",
            },
            [defaultTheme.breakpoints.up("lg")]: {
              fontSize: "2.5rem",
              marginBottom: "1.8175rem",
            },
            [defaultTheme.breakpoints.up("xl")]: {
              fontSize: "2.5rem",
              marginBottom: "1.8175rem",
            },
          },
          h3: {
            // whiteSpace: "nowrap",
            fontSize: "2rem",
            fontFamily: titleFont,
            fontWeight: 400,
            marginBottom: "1.5rem",
            // paddingBottom: "2rem",
            // [defaultTheme.breakpoints.up("sm")]: {
            //   fontSize: "1.5rem",
            // },
            // [defaultTheme.breakpoints.up("md")]: {
            //   fontSize: "1.8rem",
            // },
            // [defaultTheme.breakpoints.up("lg")]: {
            //   fontSize: "2.0rem",
            // },
          },
          h4: {
            fontSize: "1.875rem",
            fontFamily: titleFont,
            fontWeight: 700,
            [defaultTheme.breakpoints.up("sm")]: {
              fontSize: "1.875rem",
            },
            [defaultTheme.breakpoints.up("md")]: {
              fontSize: "1.875rem",
            },
            [defaultTheme.breakpoints.up("lg")]: {
              fontSize: "1.875rem",
            },
          },
          h5: {
            // whiteSpace: "nowrap",
            fontSize: "1.4107142857142856rem",

            fontFamily: titleFont,
            fontWeight: 700,
            [defaultTheme.breakpoints.up("sm")]: {
              fontSize: "1.2rem",
            },
            [defaultTheme.breakpoints.up("md")]: {
              fontSize: "1.6867rem",
            },
            [defaultTheme.breakpoints.up("lg")]: {
              fontSize: "1.4rem",
            },
          },
          h6: {
            fontFamily: titleFont,
            fontWeight: 700,
            // whiteSpace: "nowrap",
          },
          body1: {
            color: defaultTheme.palette.common.black,
            maxWidth: defaultTheme.breakpoints.values.lg,
            letterSpacing: 0.64,
            lineHeight: "2.1875rem",
            fontSize: 14,
          },
          caption: {
            fontFamily: titleFont,
            lineHeight: "2rem",
            fontSize: 14,
          },
        },
      },
      //   MuiInputBase: {
      //     input: {
      //       fontSize: "1.5rem",
      //       fontFamily: "Helvetica",
      //     },

      //     inputMultiline: {
      //       lineHeight: "1.5rem",
      //     },
      //   },
      MuiFormLabel: {
        root: {
          fontSize: "1.2rem",

          // color: "red",
        },
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: font,
    },
  })
  // { factor: 3 }
);
