import React from "react";
import { CircularProgress, Typography } from "@mui/material";
export default function LoadingProgress({ isLoading, ...props }) {
  return (
    <>
      {isLoading && (
        <div style={{ width: "100dvw", textAlign: "center" }}>
          <CircularProgress sx={{ mt: "5rem" }} thickness={1} size={100} />
          <Typography>Building a great community...</Typography>
        </div>
      )}
    </>
  );
}
