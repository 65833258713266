import React from "react";
import styled from "@emotion/styled";
import { Container, Grid, Typography, Box } from "@mui/material";
import { Helmet } from "react-helmet";
export default function Showhome() {
  return (
    <Box
      component={Container}
      maxWidth={"lg"}
      disableGutters
      align="center"
      sx={{ paddingTop: "8rem", paddingBottom: "8rem" }}
    >
      <Helmet>
        <title itemProp="name" lang="en">
          Showhome Example page
        </title>
      </Helmet>
      <Grid container direction={"column"} alignItems="center" justifyContent={"space-evenly"}>
        <img
          src="https://cms.clearwaterchestermere.com/uploads/Calbridge_Logo_K_a9a4c69ec6.svg"
          width="300px"
          height="auto"
          alt="Calbridge Logo"
        />
        <Box
          component="img"
          sx={{ marginTop: "6rem" }}
          src="https://cms.clearwaterchestermere.com/uploads/Generic_Showhome_443dc93406.jpg"
          width="60%"
          height="auto"
          alt="House"
        />
        <Box
          component="img"
          sx={{ marginTop: "6rem" }}
          src="https://cms.clearwaterchestermere.com/uploads/Thumbnails_Placeholder_da9771a7ba.jpg"
          width="930px"
          height="auto"
          alt="Thumbnails"
        />
        <Box
          component={Typography}
          sx={{ marginTop: "6rem", marginBottom: "6rem" }}
          variant="body1"
        >
          In the first release of Clearwater Park, Calbridge Homes will be building front-drive and
          street town models across various forms and sizes. Each homeowner will get to take
          advantage of our thoughtfully-curated specification levels and our 78 Design Studio
          offering a streamlined design process for you to make all of your selections at one
          convenient location. All of this while working with one of our experienced designers to
          ensure your selection process remains on schedule and budget.
        </Box>
        <Grid item container sx={{ mb: "6rem", mt: "6rem" }}>
          <Grid item xs={6}>
            <img
              src="https://cms.clearwaterchestermere.com/uploads/Floorplan_95e2847b46.png"
              width="534px"
              height="auto"
              alt="Floorplan"
            />
          </Grid>
          <Grid item container xs={5} alignContent={"center"} justifyContent={"center"}>
            <ListWrapper>
              <Typography variant="body1" align="left" sx={{ fontWeight: 800 }} paragraph>
                HIGHLIGHTS
              </Typography>
              <Typography variant="body1" component="li">
                1,318 sq. ft.
              </Typography>
              <Typography variant="body1" component="li">
                1 Bedroom, 1.5 Bathroom
              </Typography>
              <Typography variant="body1" component="li">
                Open concept living
              </Typography>
              <Typography variant="body1" component="li">
                Main level den
              </Typography>
              <Typography variant="body1" component="li">
                Opt. 866 sq ft lower level development
              </Typography>
              <Typography variant="body1" component="li">
                Walkthrough mud room to pantry
              </Typography>
            </ListWrapper>
          </Grid>
        </Grid>
        <Typography variant="h2">Showhome located at 111 Clearwater Blvd</Typography>
        <img
          src="https://cms.clearwaterchestermere.com/uploads/Generic_Location_Map_c7c9d44ab4.jpg"
          width="100%"
          height="auto"
          alt="Map"
        />
      </Grid>
    </Box>
  );
}
const ListWrapper = styled.ul`
  padding-left: 0;

  & > li {
    text-align: left;
    line-height: 2;
  }
`;
