import React from "react";
import { Grid, Box, useMediaQuery } from "@mui/material";
import { parseWithLinks } from "../Functions";
import styled from "@emotion/styled";

import StrapiGrid from "./StrapiGrid";
import SimpleResponsivePicture from "./SimpleResponsivePicture";

// import Welcome from "../assets/welcome.svg";
export default function Hero(props) {
  //TODO Figure out how to get the image on top on phone. reversing direction doesn't work???
  const isSmall = useMediaQuery(props.theme.breakpoints.down("sm"));
  const isXsmall = useMediaQuery(props.theme.breakpoints.only("xs"));
  return (
    <Wrapper
      options={{
        alignContent: "center",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {isSmall && (
        <Grid
          xs={12}
          md={12}
          item
          container
          sx={{ position: "relative" }}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <Box
            sx={{
              position: "absolute",
              color: "white",
              zIndex: 99,
            }}
          >
            {parseWithLinks(props?.HeroCopy)}
          </Box>
          <SimpleResponsivePicture
            imageObj={props?.Image?.data[0]?.attributes || props?.Image?.data[0]?.attributes?.url}
            sx={{
              height: "calc(100dvh - 4rem)",
              objectFit: "cover",
              objectPosition: "-370px center",
            }}
            sizes={
              "(max-width:430px and min-width:0) 460px,  (max-width:600px and min-width:431px ) 640px, (max-width:1200px and min-width:601px) 1200px, calc(100dvw)"
            }
            fetchpriority="high"
            objectFit={"contain"}
          />
        </Grid>
      )}

      {!isSmall && (
        <Grid
          xs={12}
          md={12}
          item
          container
          sx={{ position: "relative" }}
          justifyContent={"center"}
          alignContent={"center"}
          alignItems={"center"}
        >
          <SimpleResponsivePicture
            imageObj={props?.Image?.data[0]?.attributes || props?.Image?.data[0]?.attributes?.url}
            sx={{
              objectFit: "contain",
              objectPosition: "left center",
            }}
            sizes={
              "(max-width:430px and min-width:0) 460px,  (max-width:600px and min-width:431px and  ) 640px, (max-width:1200px and min-width:601px) 1200px, calc(100dvw)"
            }
            objectFit={"contain"}
          />
          <Box
            sx={{
              position: "absolute",
              color: "white",
              paddingLeft: { xs: "3rem" },
              paddingRight: { xs: "3rem" },
            }}
          >
            {parseWithLinks(props?.HeroCopy)}
          </Box>
        </Grid>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(StrapiGrid)`
  p {
    color: white;
  }

  .MuiTypography-body1 {
    font-size: 1.5625rem;
    width: 100%;
    color: white;

    ${props => props.theme.breakpoints.up("sm")} {
      // width: 779px;
      white-space-collapse: preserve-breaks;
    }

    ${props => props.theme.breakpoints.up("md")} {
      width: 779px;
      white-space-collapse: preserve-breaks;
    }
  }

  .MuiTypography-h1 {
    white-space-collapse: preserve-breaks;
  }

  .MuiButton-contained {
    background-color: ${props => props.theme.palette.common.white};
    color: ${props => props.theme.palette.common.black};
    font-size: 0.75rem;
    margin-top: 2rem;
    padding: 0 1.5rem;
    width: max-content;
    height: 65px;
    text-transform: uppercase;
    border-radius: 200px;
    border: none;
    font-weight: normal;

    &:after {
      content: unset;
    }

    &:hover {
      color: ${props => props.theme.palette.common.white};
      background-color: rgb(55, 147, 155);
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    ${props => props.theme.breakpoints.up("md")} {
      font-size: initial;
      padding: 0.5rem 2.5rem;
    }
  }
`;
