import React, { useState, useEffect } from "react";
import { Typography, Container, Box } from "@mui/material";
import { getStrapiContent } from "../Functions";
import PropertyListing from "./PropertyListing";
import { Outlet } from "react-router";

export default function CurrentProjects({ handlePropertyClick, ...props }) {
  const [PropertyData, setPropertyData] = useState([]);

  useEffect(() => {
    setPropertyData(props?.PropertyData);
  }, [props.PropertyData]);

  return (
    <Box component={Container} sx={{ padding: { md: "0 3rem", xs: "" } }} maxWidth="lg">
      {/* <Typography variant="h2">{props?.Title}</Typography> */}
      {PropertyData && (
        <PropertyListing
          handlePropertyClick={handlePropertyClick}
          propertiesData={PropertyData?.filter(
            (item, index) =>
              item?.attributes?.Status === "Current Project" ||
              item?.attributes?.Status === "For Sale"
          )}
        />
      )}
      <Outlet />
    </Box>
  );
}
