import axios from "axios";

const qs = require("qs");
const defaultConfig = {
  headers: {
    Authorization: "Bearer " + process.env.REACT_APP_STRAPI_ACCESS_TOKEN,
  },
};
export default async function getDataFromEndpoint(path, config = defaultConfig) {
  //"http://[API_ENDPOINT]:1337/api/home/?populate[Hero][populate]=%2A&populate[Intro][populate]=%2A"

  const query = qs.stringify(
    {
      populate: "deep",
    },
    {
      encodeValuesOnly: true,
    }
  );

  // localStorage.removeItem(key);
  return await axios
    .get(`${process.env.REACT_APP_CMS_URL}${path}?${query}`, config)
    .then(res => {
      return res;
    })
    .catch(function (error) {
      // getLocalStorage(setter, key);
      console.log(`Error fetching page data from server :: ${path}`, error.message);
      return error;
    });
}
