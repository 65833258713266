import React from "react";
import styled from "@emotion/styled";

export default function TextMarkerRotated({ text, currentZoom, style, angle, ...props }) {
  return (
    <Wrapper style={style} angle={angle}>
      {text}
      {/* {currentZoom} */}
    </Wrapper>
  );
}
const Wrapper = styled("div")`
  position: absolute;
  transform: rotate(${props => props.angle}) translate(-50%, 100%);
  cursor: grab;
  width: max-content;
  text-align: center;
  // vertical-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: transparent;
`;
