import React from "react";
import { Container, Box, Typography } from "@mui/material";
import Gallery from "./Gallery";
import { parseWithLinks } from "../Functions";

export default function MultipleImages(props) {
  const disableGutters = props?.options?.wrapperStyle?.disableGutters || true;
  return (
    <Box
      component={Container}
      maxWidth={"lg"}
      align="center"
      sx={props?.options?.wrapperStyle?.sx}
      disableGutters={disableGutters}
    >
      {parseWithLinks(props.Title)}
      <Gallery images={props?.Images?.data} options={props?.options} />
    </Box>
  );
}
