import React from "react";

import { Grid, Container, Typography } from "@mui/material";

import BuilderCard from "./BuilderCard";

export default function BuilderListing(props) {
  const disableGutters = props?.isXsmall || props?.isSmall ? false : true;

  return (
    <Container maxWidth={"lg"} disableGutters={disableGutters}>
      <Typography variant="h2" align="center" pb={"4rem"}>
        Meet Our Builders
      </Typography>
      <Grid container spacing={{ xs: 0, sm: 5, md: 10 }}>
        {props?.home_builders?.data?.map((builder, index) => {
          return (
            <Grid key={builder.attributes.Description} item xs={12} sm={6}>
              <BuilderCard {...builder.attributes} />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
