import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { Grid, Card, CardMedia } from "@mui/material";

import PropertyTitle from "./PropertyTitle";

export default function PropertyCard({
  propertyType = "commercial",
  data,
  image,
  id,
  slug,
  handlePropertyClick,
  ...props
}) {
  const [imageHeight, setImageHeight] = useState();

  const handleResize = () => {
    const width = document.querySelector("#listing0")?.clientWidth;
    setImageHeight(() => width);
  };

  useEffect(() => {
    setImageHeight(document.querySelector("#listing0")?.clientWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const title = data.Title;

  return (
    <Card
      sx={{
        boxShadow: "none",
        paddingBottom: { xs: "2rem" },
        bgcolor: "transparent",
      }}
      component={Grid}
      item
      xs={12}
      sm={6}
      md={4}
    >
      <PropertyTitle data={title} />

      <NavLink
        to={`/leasing-and-sales/${slug}`}
        onClick={handlePropertyClick}
        data-id={id}
        data-slug={slug}
        alt={title}
      >
        <CardMedia
          id={`listing${props.index}`}
          component={"img"}
          height={imageHeight}
          alt={title}
          //TODO handle no image with a placeholder image
          image={`${image}`}
        />
      </NavLink>
    </Card>
  );
}
