import React from "react";
import styled from "@emotion/styled";
import { Grid, Container, useMediaQuery } from "@mui/material";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import { parseWithLinks } from "../Functions";

//TODO fix line below image with an explicit height or crop images to fit space exactly. Might have to rethink the layout.
export default function Hero2({ Copy, theme, ...props }) {
  const isPhone = useMediaQuery(theme?.breakpoints?.down("sm"));

  //TODO should use regex and ignore case
  if (Copy === "<p>our community</p>" && isPhone) {
    Copy = "<p>community</p>";
  }
  return (
    <Container maxWidth="xl" disableGutters>
      <Grid
        container
        flexDirection={isPhone ? "column-reverse" : "row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ position: "relative", height: 400 }}
      >
        <SimpleResponsivePicture imageObj={props?.imageObj} sx={{ height: 400, width: "100dvw" }} />
        <TextWrapper
          container
          justifyContent="center"
          alignItems="center"
          // dangerouslySetInnerHTML={{ __html: Copy }}
        >
          {parseWithLinks(Copy)}
        </TextWrapper>
      </Grid>
    </Container>
  );
}

const TextWrapper = styled(Grid)`
  width: 100%;
  height: 100%;
  position: absolute;
  color: ${props => props.theme.palette.common.white};
  .MuiTypography-h1 {
    text-shadow: 0px 1px 2px black;
  }
  h1 {
    text-align: center;

    // white-space: pre;
    max-width: 90dvw;
    ${props => props.theme.breakpoints.up("sm")} {
      // white-space: unset;
    }
  }
`;
