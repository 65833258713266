import React from "react";
import styled from "@emotion/styled";

import CatalystMapLocation from "./assets/catalyst_map_location.svg";
export default function ProjectMarker({ currentZoom, ...props }) {
  return <ProjectMarkerImg zoom={currentZoom} src={CatalystMapLocation} />;
}

const ProjectMarkerImg = styled("img")`
  transform: translate(0, -100%);
  min-width: ${props => (props.zoom >= 13 ? "150px" : "250px")};
`;
