import React from "react";
import ImageGallery from "react-image-gallery";
import { cleanCaption } from "../../Functions";
import "react-image-gallery/styles/css/image-gallery.css";

export default function Gallery({ options, ...props }) {
  const images = props?.images?.map((image, index) => {
    const returnObject = {
      srcSet: `${image?.attributes?.formats?.small?.url} 320w,${
        image?.attributes?.formats?.medium?.url
      } 640w,${image?.attributes?.formats?.large?.url || image?.attributes?.url} 1280w,${
        image?.attributes?.formats?.xlarge?.url || image?.attributes?.url
      } 1920w`,
      sizes: "(min-width: 768px) 50vw, 100vw",
      original: `${
        image?.attributes?.formats?.xlarge?.url ||
        image?.attributes?.formats?.large?.url ||
        image?.attributes?.formats?.medium?.url ||
        image?.attributes?.formats?.small?.url ||
        image?.attributes?.url
      }`,
      thumbnail: `${image?.attributes?.formats?.thumbnail?.url}`,
      fullscreen: `${image?.attributes?.url}`,
      description: cleanCaption(image?.attributes?.caption, ""),
    };

    return returnObject;
  });

  return <ImageGallery items={images} {...options.settings} />;
}
