import React from "react";
import styled from "@emotion/styled";

export default function TextMarker({ text, currentZoom, style, ...props }) {
  return (
    <Wrapper style={style}>
      {text}
      {/* {currentZoom} */}
    </Wrapper>
  );
}
const Wrapper = styled("div")`
  position: absolute;
  transform: translate(-50%, -50%);
  cursor: grab;
  width: max-content;
  text-align: center;
  // vertical-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: transparent;
`;
