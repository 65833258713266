import React from "react";
import {
  Card,
  CardMedia,
  CardActions,
  CardContent,
  Typography,
  Button,
  Link,
  Box,
} from "@mui/material";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import { Link as RouterLink } from "react-router-dom";

import { parseWithLinks } from "../Functions";
export default function BuilderCard(props) {
  return (
    <Card
      sx={{
        boxShadow: 6,
        paddingBottom: "2rem",
      }}
    >
      <CardMedia
        component={SimpleResponsivePicture}
        sx={{ height: { xs: "auto", md: "auto", lg: 340 } }}
        objectFit={{ xs: "cover", sm: "cover", md: "contain", lg: "cover" }}
        objectPosition="center center"
        imageObj={props?.image?.data?.attributes}
      />
      <CardContent sx={{ p: { xs: "3rem 1rem", md: "4rem 2rem 3rem" } }}>
        <Typography variant="h4" align="left" paragraph>
          {props?.Name}
        </Typography>
        <Box sx={{ minHeight: 200 }}>
          {parseWithLinks(props?.Description, { alignText: "left" })}
        </Box>
        <Typography variant="body1" fontWeight={800} pb={"1.33rem"}>
          CONTACT
        </Typography>

        {props?.email && (
          <Box pb="1rem">
            <Link underline="none" href={`mailto:${props?.email}`}>
              {props?.email}
            </Link>
          </Box>
        )}
        <Box>
          <Link underline="none" href={`mailto:${props?.phone}`}>
            {props?.phone}
          </Link>
        </Box>
      </CardContent>
      {/* <CardActions sx={{ p: { xs: "1rem", md: "2rem 2rem 1rem" } }}>
        <Button variant="contained" fullWidth component={RouterLink} to={`/showhome`}>
          See Showhome & Floorplans
        </Button>
      </CardActions> */}
      <CardActions sx={{ p: { xs: "1rem", md: "1rem 2rem 2rem" } }}>
        <Button variant="contained" fullWidth href={props?.url} target="Clearwater">
          Visit {props?.Name}
        </Button>
      </CardActions>
    </Card>
  );
}
