import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";

function Marker({ handleMarkerClick, lineColor, currentZoom, ...props }) {
  const [showLabel, setShowLabel] = useState(false);
  function makeTravelTimes(timeObject) {
    const availableTimes = Object.keys(timeObject[0]);

    const times = availableTimes.map((mode, index) => {
      return (
        <Typography key={mode} variant="caption">
          {`${mode.charAt(0).toUpperCase()}${mode.slice(1)}: ${timeObject[0][mode]} minutes`}
        </Typography>
      );
    });

    return times;
  }

  return (
    <MarkerContainer
      className="location-marker"
      // style={{ translate: "(-50%,-100%)" }}
      onClick={e => handleMarkerClick(e, setShowLabel)}
      data-lat={props.lat}
      data-lng={props.lng}
    >
      <Typography variant="caption" style={{ color: lineColor }}>
        {props.name}
      </Typography>

      {props?.time && makeTravelTimes(props?.time)}
    </MarkerContainer>
    // <div style={{ width: 75, height: 37, backgroundColor: "#682b4d", color: "white" }}></div>
  );
}

const MarkerContainer = styled("div")`
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: max-content;
  max-width: 150px;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  // border: 0.5px solid ${props => props.theme.palette.primary.main};
  z-index: 10;
  .MuiTypography-caption {
    font-weight: bold;
  }
  z-index: 20;
  &:hover {
    cursor: default;
  }
`;
Marker.propTypes = {};

export default Marker;
