import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Box, Container, Typography, Grid, Button } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { parseWithLinks } from "../Functions";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import NewsPlaceholderImage from "./NewsPlaceholderImage";

export default function NewsDetail(props) {
  const { id, slug } = useParams();
  const [NewsDetailId, setNewsDetailId] = useState();
  const [NewsDetail, setNewsDetail] = useState();

  useEffect(() => {
    setNewsDetailId(() => Number(id));
    setNewsDetail(() => {
      return props.data.filter((item, index) => {
        return Number(item.id) === NewsDetailId;
      })[0];
    });
  }, [id, NewsDetailId]);

  const displayDate = new Date(`${NewsDetail?.PublishDate.replace(/-/g, "/")} MST`);

  // Array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the month, day, and year
  const month = monthNames[displayDate.getMonth()];
  const day = displayDate.getDate();
  const year = displayDate.getFullYear();

  // Create the formatted string
  const formattedDateString = `${month} ${day}, ${year}`;
  return (
    <Wrapper
      component={Container}
      maxWidth={"lg"}
      p={{ xs: "4rem 1rem 0", md: "6rem 4rem" }}
      align="left"
    >
      <Button
        component={Link}
        to="/news"
        variant="contained"
        sx={{ marginBottom: "5rem" }}
        startIcon={<KeyboardArrowLeftIcon />}
      >
        Back to News & Blog
      </Button>
      <Typography variant="body1" color="primary.secondary">
        {formattedDateString}
      </Typography>
      <Typography
        variant="body1"
        paragraph
        fontWeight={800}
        color="primary.main"
        textTransform={"uppercase"}
      >
        {NewsDetail?.Title}
      </Typography>
      <Typography
        variant="body1"
        paragraph
        fontWeight={800}
        color="primary.main"
        textTransform={"uppercase"}
      >
        {props?.newsDetail?.Title}
      </Typography>
      <Box
        component={Grid}
        container
        direction={{ xs: "column-reverse", md: "row" }}
        alignItems={"flex-start"}
        spacing={6}
      >
        <Grid item container xs={12}>
          {NewsDetail?.Image?.data && NewsDetail?.Url && (
            <Link to={NewsDetail?.Url} target="Clearwater">
              <SimpleResponsivePicture
                imageObj={NewsDetail?.Image?.data[0]?.attributes}
                alt={NewsDetail?.Title}
              />
              {/* <img
                src={NewsDetail?.Image?.data[0]?.attributes?.url}
                width={"100%"}
                height="auto"
                alt={NewsDetail?.Title}
              /> */}
            </Link>
          )}
          {NewsDetail?.Image?.data && !NewsDetail?.Url && (
            <SimpleResponsivePicture
              imageObj={NewsDetail?.Image?.data[0]?.attributes}
              alt={NewsDetail?.Title}
            />
          )}
        </Grid>
        {NewsDetail?.Summary && (
          <Grid item xs={12} justifyContent={"flex-start"}>
            {parseWithLinks(NewsDetail?.Summary, { alignText: "left" })}
            {props?.newsDetail?.Url && (
              <Link to={NewsDetail?.Url} align="left" target="Clearwater">
                <Typography variant="body1">Read the full article here...</Typography>
              </Link>
            )}
          </Grid>
        )}
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  position: relative;
  .image.image-style-side {
    // margin: 0;
    img {
      // margin: 0 0 6rem 6rem;
      float: right;

      width: 270px;
      height: auto;
    }
  }
  .image.image_resized {
    margin: 2rem 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  figure.image.image_resized.image-style-side {
    float: right;
    margin: 2.1rem 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  .image {
    img {
      margin: 0 0 2rem 2rem;
    }
  }
  img {
    max-width: 1072px;
    height: auto;
  }
`;
