import React, { useEffect, useState } from "react";

import { getStrapiContent } from "../Functions";
import { Grid, Container, Typography } from "@mui/material";
import { Outlet } from "react-router";

import PropertyCard from "./PropertyCard";
// import LeasingUpdates from "./LeasingUpdates";

export default function PropertyListing({
  propertiesData,
  pdf,
  leasingDescription,
  handlePropertyClick,
  ...props
}) {
  const [Categories, setCategories] = useState();
  const [ShowSaleProperties, setShowSaleProperties] = useState();

  useEffect(() => {
    // Build an array with the used categories.
    // use this to build sections.
    const CATEGORIES = propertiesData?.map((property, index) => {
      return property.attributes.Category;
    });
    setCategories([...new Set(CATEGORIES)]);
  }, [propertiesData]);

  useEffect(() => {
    setShowSaleProperties(
      propertiesData.filter((property, index) => {
        if (property.attributes.Status === "For Sale") {
          return true;
        }
        return false;
      }).length > 0
    );
  }, [propertiesData]);

  return (
    <>
      {Categories &&
        Categories?.map((category, index) => {
          //
          // First filter the properties, removing any that are "For Sale"
          // since these are displayed separately at the bottom of the page.
          // I then set the default sort order to be by Title
          // then use the order property to do the final sort.

          const SortedAndFilteredProperties = propertiesData
            ?.filter((property, index) => {
              if (property?.attributes?.Status === "For Sale") {
                return false;
              }
              if (property.attributes.Category === category) {
                return true;
              }
              return false;
            })
            .sort((a, b) => {
              if (b.attributes.Title > a.attributes.Title) {
                return -1;
              }
              if (b.attributes.Title < a.attributes.Title) {
                return 1;
              }
              return 0;
            })
            .sort((a, b) => {
              if (b.attributes.order > a.attributes.order) {
                return -1;
              }
              if (b.attributes.order < a.attributes.order) {
                return 1;
              }
              return 0;
            });
          return (
            <Grid
              key={`${category}${index}`}
              container
              columnSpacing={4}
              rowSpacing={{ xs: 0, sm: 1 }}
            >
              <Grid item xs={12}>
                <Typography variant="h2" align="left" sx={{ width: "100%" }}>
                  {category} Leasing
                </Typography>
              </Grid>
              {SortedAndFilteredProperties.map((property, index, array) => {
                let image = "";
                const hasImage = property?.attributes?.photo?.data?.attributes?.Images?.data;

                // if it has a small use it, otherwise use the medium, if neither use the original
                if (hasImage) {
                  if (hasImage[0]?.attributes?.formats?.hasOwnProperty("small")) {
                    image = hasImage[0]?.attributes?.formats?.small?.url;
                  }
                  if (hasImage[0]?.attributes?.formats?.hasOwnProperty("medium")) {
                    image = hasImage[0]?.attributes?.formats?.medium?.url;
                  }
                  if (
                    !hasImage[0]?.attributes?.formats?.hasOwnProperty("medium") &&
                    !hasImage[0]?.attributes?.formats?.hasOwnProperty("small")
                  ) {
                    image = hasImage[0]?.attributes?.url;
                  }
                }

                return (
                  <PropertyCard
                    key={property?.attributes?.Title + index}
                    data={property?.attributes}
                    handlePropertyClick={handlePropertyClick}
                    id={property?.id}
                    image={image}
                    index={index}
                    isSmall={props?.isSmall}
                    slug={property?.attributes?.slug}
                  />
                );
              })}
            </Grid>
          );
          //
        })}

      {ShowSaleProperties && (
        <Grid container columnSpacing={4} rowSpacing={{ xs: 0, sm: 1 }}>
          <Grid item xs={12}>
            <Typography variant="h2" align="left" sx={{ width: "100%" }}>
              Projects for Sale
            </Typography>
          </Grid>
          {propertiesData &&
            propertiesData
              ?.sort((a, b) => {
                if (b.attributes.order > a.attributes.order) {
                  return -1;
                }
                if (b.attributes.order < a.attributes.order) {
                  return 1;
                }
                return 0;
              })
              .filter((property, index) => {
                if (property.attributes.Status === "For Sale") {
                  return true;
                }
                return false;
              })
              .map((property, index) => {
                let image = "";
                const hasImage = property?.attributes?.photo?.data?.attributes?.Images?.data;

                // if it has a small use it, otherwise use the medium, if neither use the original
                if (hasImage) {
                  if (hasImage[0]?.attributes?.formats?.hasOwnProperty("small")) {
                    image = hasImage[0]?.attributes?.formats?.small?.url;
                  }
                  if (hasImage[0]?.attributes?.formats?.hasOwnProperty("medium")) {
                    image = hasImage[0]?.attributes?.formats?.medium?.url;
                  }
                  if (
                    !hasImage[0]?.attributes?.formats?.hasOwnProperty("medium") &&
                    !hasImage[0]?.attributes?.formats?.hasOwnProperty("small")
                  ) {
                    image = hasImage[0]?.attributes?.url;
                  }
                }

                return (
                  <PropertyCard
                    key={property?.attributes?.Title + index}
                    data={property?.attributes}
                    handlePropertyClick={handlePropertyClick}
                    id={property?.id}
                    image={image}
                    index={index}
                    slug={property?.attributes?.slug}
                  />
                );
              })}
        </Grid>
      )}
    </>
  );
}
