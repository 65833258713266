import React from "react";
import styled from "@emotion/styled";
import { Container, Grid, Box } from "@mui/material";

import ImageLeft from "./ImageLeft";

import { ButtonStyles } from "../ButtonStyles";
import { parseWithLinks } from "../Functions";

export default function WhatWeDo(props) {
  return (
    <Container maxWidth={"xl"} disableGutters>
      <ImageLeft
        Copy={props?.TopSection?.Copy}
        Image={props?.TopSection?.Image}
        options={props?.TopSection?.options}
      />

      <TitleWrapper align="center" dangerouslySetInnerHTML={{ __html: props.ServicesTitle }} />

      <Grid container maxWidth={"lg"} p={{ xs: "1rem 0", md: "2rem 0 4rem" }}>
        {props.Services.map((service, index) => {
          return (
            <Wrapper
              key={service.ExpandedText}
              bg={service.graphic.data.attributes.url}
              xs={12}
              md={6}
              container
              item
              justifyContent="center"
              alignItems="center"
            >
              <CopyWrapper
                color="common.white"
                maxWidth="80%"
                textAlign={"left"}
                buttonstyles={ButtonStyles}
              >
                {parseWithLinks(service.ExpandedText)}
              </CopyWrapper>
            </Wrapper>
          );
        })}
      </Grid>
    </Container>
  );
}
const TitleWrapper = styled(Box)``;

const Wrapper = styled(Grid)`
  background: center / cover no-repeat url(${props => props.bg});
  height: 421px;
  position: relative;
`;

const CopyWrapper = styled(({ buttonstyles, ...props }) => <Box {...props} />)`
  position: absolute;
  bottom: 0;
  p {
    margin-bottom: 0;
  }
  ${props => {
    return props.buttonstyles;
  }};
  a.button {
    margin-top: 2rem;
    width: auto;
    ${props => props.theme.breakpoints.up("md")} {
      width: -webkit-max-content;
      width: -moz-max-content;
      width: auto;
    }
  }
  p,
  h1,
  h2,
  h3,
  h4 {
    color: ${props => props.theme.palette.common.white}!important;
  }
`;
