import React from "react";
import styled from "@emotion/styled";
import { Container, Box } from "@mui/material";
import { addClassToLinksInParagraphs, parseWithLinks } from "../Functions";
export default function RichTextStandard({ theme, ...props }) {
  let result = props.Copy;

  const layout = props?.options.layout || {};

  const wrapperStyle = props?.options?.wrapperStyle || {};
  const textBlockStyle = props?.options?.textBlockStyle || {};

  if (layout?.useButtonForLink) {
    result = addClassToLinksInParagraphs(result, "button", "_blank");
  }

  return (
    <Wrapper
      component={Container}
      disableGutters
      style={{ ...wrapperStyle, ...props?.options?.style }}
      sx={{ ...wrapperStyle, ...props?.options?.style }}
      theme={theme}
    >
      {parseWithLinks(props.Copy, { textAlign: "center", ...textBlockStyle })}
    </Wrapper>
  );
}
const Wrapper = styled(Box)`
  text-align: left;
  max-width: 100dvw !important;
`;
