import React from "react";
import { Box, Container } from "@mui/material";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import Description from "./Description";
import { removeCommentAttributes } from "../Functions";

export default function GraphicAndText({ ExpandedText = "", ...props }) {
  const wrapperStyle = removeCommentAttributes(props?.options?.wrapperStyle) || {};
  const descriptionStyle = removeCommentAttributes(props?.options?.textBlockStyle) || {};
  const imageBlockStyle = removeCommentAttributes(props?.options?.imageBlockStyle) || {};

  return (
    <Box
      {...wrapperStyle}
      component={Container}
      disableGutters={wrapperStyle?.disableGutters || true}
      maxWidth={wrapperStyle?.maxWidth || "lg"}
    >
      <SimpleResponsivePicture
        imageObj={props?.graphic?.data?.attributes}
        {...imageBlockStyle?.imageStyle}
      />
      <Description
        Copy={ExpandedText}
        options={{ wrapperStyle: wrapperStyle, textBlockStyle: descriptionStyle }}
      />
    </Box>
  );
}
