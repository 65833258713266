import React, { useState, useEffect } from "react";

import { fitBounds } from "google-map-react";

import {
  boundsArray,
  initialBounds,
  restArray,
  ParksArray,
  SchoolMedicalArray,
  ShopArray,
} from "./data/GoogleMapsData";

import logo from "./assets/logo.svg";
import base from "./assets/base.jpg";

import styled from "@emotion/styled";
import {
  Typography,
  Container,
  AppBar,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  Table,
  TableBody,
} from "@mui/material";

import GoogleMapComponent from "./GoogleMapComponent";

function Maps({ data, ...props }) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [mapFile, setMapFile] = useState(0);
  const [currentZoom, setCurrentZoom] = useState();
  const [mapSize, setMapSize] = useState({ width: "", height: "" });
  const [bounds, setBounds] = useState(initialBounds);
  useEffect(() => {
    // console.log(window.innerHeight, window.innerWidth);
    // console.log(window.innerHeight < 816 ? window.innerHeight - 170 : 816);
    setMapSize({
      width: window.innerWidth,
      height: window.innerHeight < 816 ? window.innerHeight - 170 : 816,
    });
  }, []);

  function handleOnChanged(mapdata) {
    // setMarkerState([]);

    setCurrentZoom(prev => mapdata.zoom);
    //this refers to Google Map instance
  }
  const getLocationsByMode = (INPUT_ARRAY, PROPERTY_TO_FIND) => {
    return {
      mode: PROPERTY_TO_FIND,
      locations: INPUT_ARRAY.filter((location, index) => {
        return location.time.find((mode, index) => {
          return mode.hasOwnProperty(PROPERTY_TO_FIND);
        });
      }).map((location, index) => {
        // debugger;
        const tempTime = location.time.find((mode, index) => {
          return mode.hasOwnProperty(PROPERTY_TO_FIND);
        });
        return {
          lat: location.lat,
          lng: location.lng,
          name: location.name,
          time: tempTime[PROPERTY_TO_FIND],
        };
      }),
    };
  };

  const buildMobileData = INPUT_ARRAY => {
    const modes = ["drive", "walk", "bike"];
    return modes.map((mode, index) => {
      return getLocationsByMode(INPUT_ARRAY, mode);
    });
  };
  const mobileRestaurantsData = buildMobileData(restArray);
  const mobileParksData = buildMobileData(ParksArray);
  const mobileSchoolMedicalData = buildMobileData(SchoolMedicalArray);
  const mobileShoppingData = buildMobileData(ShopArray);

  const MobileData = {
    parks: mobileParksData,
    restaurant: mobileRestaurantsData,
    shopping: mobileShoppingData,
    schoolmedical: mobileSchoolMedicalData,
  };

  const handleChange = (event, newValue) => {
    // const size = { width: 390, height: 800 };

    const size = { width: 1280, height: 816 };
    setValue(newValue);
    setMapFile(() => data[newValue]?.MapFile);

    setBounds(() => fitBounds(boundsArray[newValue], mapSize));
  };

  const handleNoMapChange = (event, newValue) => {
    setValue(newValue);
  };
  // const useScrollTabs = useMediaQuery(theme.breakpoints.down("sm"));
  const useScrollTabs = useMediaQuery(theme.breakpoints.only("xs"));

  const TabColor = key => {
    let lineColor = "#000000";
    switch (key) {
      case "RESTAURANTS & BARS":
        lineColor = "#682b4d";
        break;
      case "PARKS & FITNESS":
        lineColor = "#00784e";
        break;
      case "SHOPPING & ENTERTAINMENT":
        lineColor = "#3e6777";
        break;
      case "EDUCATION & MEDICAL":
        lineColor = "#a13a59";
        break;
      case "TRANSIT":
        lineColor = "#cc6a2d";
        break;

      default:
        break;
    }
    return lineColor;
  };

  return (
    <>
      <Wrapper maxWidth="lg" disableGutters>
        <AppBar
          // component={Grid}
          // container
          // justifyContent="space-evenly"
          position="static"
          style={{ width: "100%" }}
          color="secondary"
          // fullWidth
        >
          <Tabs
            value={value}
            onChange={useScrollTabs ? handleNoMapChange : handleChange}
            aria-label="Catalyst Maps"
            variant={useScrollTabs ? "scrollable" : "fullWidth"}
            scrollButtons="on"
            centered={useScrollTabs ? false : true}
            selectionFollowsFocus={true}
          >
            {/* <Tab label="Item One" {...a11yProps(0)} /> */}
            {data?.map((unit, index) => {
              const tabLabel = <Typography variant="body1"> {unit.Name}</Typography>;
              return (
                <Tab
                  // component={Grid}
                  // item
                  style={{
                    borderBottom: `10px solid ${TabColor(unit.Name)}`,
                    borderWidth: "0 0 10px 0",
                    marginRight: 2,
                  }}
                  xs={10}
                  sm={2}
                  key={unit.Name}
                  label={tabLabel}
                  // TabColor={TabColor}
                  id={`maps-tab-${index}`}
                  aria-controls={`maps-tabpanel-${index}`}
                  tabIndex={6 + index}
                />
              );
            })}
          </Tabs>
        </AppBar>

        {/* Start Mobile version */}
        {value !== 4 && useScrollTabs && (
          <Container style={{ marginBottom: "1rem" }}>
            {value === 0 &&
              MobileData.parks.map((type, index) => {
                return (
                  <Table key={type.mode + index}>
                    <TableBody>
                      <Typography
                        color={"primary"}
                        align="left"
                        style={{
                          marginTop: "1rem",
                          marginBottom: "1rem",
                          borderBottom: `solid 1px ${theme.palette.primary.main}`,
                        }}
                        variant="h1"
                      >
                        {type.mode}
                      </Typography>
                      {type.locations.map((location, index) => {
                        return (
                          <Typography
                            key={location.name}
                            style={{ color: "#000000" }}
                            variant="body1"
                            align="left"
                          >
                            <strong>{location.time} min</strong> - {location.name}
                          </Typography>
                        );
                      })}
                    </TableBody>
                  </Table>
                );
              })}
            {value === 1 &&
              MobileData.restaurant.map((type, index) => {
                return (
                  <Table key={type.mode + index}>
                    <TableBody>
                      <Typography
                        color={"primary"}
                        align="left"
                        style={{
                          marginTop: "1rem",
                          marginBottom: "1rem",
                          borderBottom: `solid 1px ${theme.palette.primary.main}`,
                        }}
                        variant="h1"
                      >
                        {type.mode}
                      </Typography>
                      {type.locations.map((location, index) => {
                        return (
                          <Typography
                            key={location.name}
                            style={{ color: "#000000" }}
                            variant="body1"
                            align="left"
                          >
                            <strong>{location.time} min</strong> - {location.name}
                          </Typography>
                        );
                      })}
                    </TableBody>
                  </Table>
                );
              })}
            {value === 2 &&
              MobileData.shopping.map((type, index) => {
                return (
                  <Table key={type.mode + index}>
                    <TableBody>
                      <Typography
                        color={"primary"}
                        align="left"
                        style={{
                          marginTop: "1rem",
                          marginBottom: "1rem",
                          borderBottom: `solid 1px ${theme.palette.primary.main}`,
                        }}
                        variant="h1"
                      >
                        {type.mode}
                      </Typography>
                      {type.locations.map((location, index) => {
                        return (
                          <Typography
                            key={location.name}
                            style={{ color: "#000000" }}
                            variant="body1"
                            align="left"
                          >
                            <strong>{location.time} min</strong> - {location.name}
                          </Typography>
                        );
                      })}
                    </TableBody>
                  </Table>
                );
              })}
            {value === 3 &&
              MobileData.schoolmedical.map((type, index) => {
                return (
                  <Table key={type.mode + index}>
                    <TableBody>
                      <Typography
                        color={"primary"}
                        align="left"
                        style={{
                          marginTop: "1rem",
                          marginBottom: "1rem",
                          borderBottom: `solid 1px ${theme.palette.primary.main}`,
                        }}
                        variant="h1"
                      >
                        {type.mode}
                      </Typography>
                      {type?.locations?.map((location, index) => {
                        return (
                          <Typography
                            key={location.name}
                            style={{ color: "#000000" }}
                            variant="body1"
                            align="left"
                          >
                            <strong>{location.time} min</strong> - {location.name}
                          </Typography>
                        );
                      })}
                    </TableBody>
                  </Table>
                );
              })}
          </Container>
        )}
        {value > 3 && useScrollTabs && (
          <Container style={{ marginBottom: "1rem", height: 499, paddingTop: "4rem" }}>
            <Typography variant="h2" style={{ color: "#000000" }}>
              Catalyst has easy access to the LRT Red Line and numerous bus routes to every part of
              the city.
            </Typography>
          </Container>
        )}
        {/* End Mobile version */}
        {value !== 4 && !useScrollTabs && (
          <MapContainer id="CatalystMap">
            <GoogleMapComponent
              bounds={bounds}
              value={value}
              // lineColor={TabColor(data[value]?.Name)}
              currentZoom={currentZoom}
              handleOnChanged={handleOnChanged}
            />
          </MapContainer>
        )}
        {value === 4 && !useScrollTabs && <MapContainer mapFile={mapFile} />}
      </Wrapper>
    </>
  );
}

const Wrapper = styled(Container)`
  .MuiSvgIcon-fontSizeSmall {
    font-size: 3rem;
  }
  .MuiTab-root.Mui-selected {
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.common.white};
    span.MuiTab-wrapper > p {
      color: ${props => props.theme.palette.common.white};
      font-weight: 700;
    }
    .MuiTypography-body1 {
      font-size: 0.7rem;
      line-height: 1rem;
    }
    ${props => props.theme.breakpoints.up("md")} {
      .MuiTypography-body1 {
        font-size: initial;
        line-height: initial;
      }
    }
  }
  .MuiTab-root {
    padding: 0;
    height: 70px;
    background-color: rgba(122, 122, 115, 1);
    opacity: 1;

    /* box-shadow: 0px 20px 0 0px red; */
    color: ${props => props.theme.palette.common.white};
    span.MuiTab-wrapper > p {
      color: ${props => props.theme.palette.common.white};
      font-weight: 700;
    }
    .MuiTypography-body1 {
      font-size: 0.7rem;
      line-height: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    ${props => props.theme.breakpoints.up("md")} {
      .MuiTypography-body1 {
        font-size: initial;
        line-height: initial;
      }
    }
  }
  .MuiTab-wrapper {
    height: calc(100% - 3px);
    box-shadow: 0px 2px 0 0px white;
  }
  .MuiAppBar-colorSecondary {
    background-color: white;
  }
  & > .MuiPaper-elevation4 {
    box-shadow: none;
  }
`;

const MapContainer = styled("div")`
  background-image: url(${logo}), url(${props => props.mapFile}), url(${base}),
    linear-gradient(
      0deg,
      ${props => props.theme.palette.common.backgroundLightGrey} 0%,
      ${props => props.theme.palette.common.backgroundLightGrey} 55%,
      rgba(255, 255, 255, 1) 55%,
      rgba(255, 255, 255, 1) 100%
    );
  background-size: 90%, 90%, 90%, 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 390px;
  height: 800px;
  ${props => props.theme.breakpoints.up("sm")} {
    width: clamp(820px, 100vw, 1280px);
    height: clamp(248.625px, calc(100vw * 0.6375), 700px);
  }

  ${props => props.theme.breakpoints.up("md")} and (max-height: 768px) {
    width: clamp(360px, 100vw, 1280px);
    height: clamp(248.625px, calc((100vw * 0.6375) - 190px), 550px);
  }
  ${props => props.theme.breakpoints.up("md")} and (max-height: 900px) {
    width: clamp(360px, 100vw, 1280px);
    height: clamp(248.625px, calc((100vw * 0.6375) - 190px), 700px);
  }
  // ${props => props.theme.breakpoints.up("md")} {
  //   width: clamp(820px, 100vw, 1280px);
  //   height: clamp(248.625px, calc(100vw * 0.6375), 800px);
  // }
`;
export default Maps;
