import React from "react";
import styled from "@emotion/styled";
import { Container, Typography } from "@mui/material";
import SimpleResponsivePicture from "./SimpleResponsivePicture";

import { addClassToLinksInParagraphs, parseWithLinks } from "../Functions";

export default function FullWidthImage(props) {
  //TODO Build Component
  const wrapperStyle = props?.options?.wrapperStyle || {};
  const imageBlockStyle = props?.options?.imageBlockStyle || {};

  let result = props.Content;
  if (props?.options?.useButtonForLink) {
    result = addClassToLinksInParagraphs(result, "button", "_blank");
  }

  return (
    <Container
      disableGutters
      {...wrapperStyle}
      maxWidth={wrapperStyle?.maxWidth || false}
      style={{ position: "relative" }}
    >
      {props?.Content && (
        <ContentBlock className="content-block">{parseWithLinks(result)}</ContentBlock>
      )}

      <SimpleResponsivePicture
        imageObj={props?.Image?.data?.attributes}
        sx={{ ...imageBlockStyle }}
      />
      <Typography variant="caption" display="block" gutterBottom align="right">
        {props?.Image?.data?.attributes?.caption}
      </Typography>
    </Container>
  );
}
const ContentBlock = styled("div")`
  h3 {
    color: white;
    width: 100%;
  }

  color: white;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
