import React from "react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import purify from "dompurify";

export default function VideoRight(props) {
  const wrapperStyle = props?.options?.wrapperStyle || {};
  const imageBlockStyle = props?.options?.imageBlockStyle || {};
  const textBlockStyle = props?.options?.textBlockStyle || {};
  return (
    <Wrapper
      container
      alignItems={"center"}
      justifyContent={"space-evenly"}
      flexDirection={{ xs: "column", md: "row" }}
      sx={{ ...wrapperStyle, ...props?.options?.style }}
    >
      <Grid
        xs={12}
        md={props?.options?.layout?.imageSize || 6}
        align="left"
        item
        sx={{ ...imageBlockStyle }}
        dangerouslySetInnerHTML={{ __html: props?.EmbedCode }}
      />
      <Grid
        xs={12}
        md={props?.options?.layout?.textSize || 3.5}
        align="center"
        item
        sx={{ ...textBlockStyle }}
        dangerouslySetInnerHTML={{ __html: purify.sanitize(props?.Copy) }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  p,
  h3 {
    text-align: left;
  }
`;
