import React, { useState } from "react";

import { Box, Tab, Tabs, Typography, Container, Grid } from "@mui/material";
import { parseWithLinks } from "../Functions";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
import SwipeableViews from "react-swipeable-views";

export default function TabSection({ isSmall, isXsmall, isTouch, Title, TabItem, ...props }) {
  const [value, setValue] = useState(0);

  const wrapperStyle = props?.options?.wrapperStyle || {};
  const defaultExpanded = props?.options?.defaultExpanded || false;
  const disableGutters = props?.options?.disableGutters || true;
  const panelBackgroundColor = props?.options?.panelBackgroundColor || "";
  const panelTextColor = props?.options?.panelTextColor || "";
  const TabIndicatorProps = props?.options?.TabIndicatorProps || {};
  const indicatorColor = props?.options?.indicatorColor || "purple";
  const visibleScrollbar = props?.options?.visibleScrollbar || false;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`style-tabpanel-${index}`}
        aria-labelledby={`style-tab-${index}`}
        {...other}
      >
        {/* {value === index && <Box sx={{ p: 3 }}>{children}</Box>} */}
        {value === index && <>{children}</>}
      </Box>
    );
  }
  function TabWrapper(props) {
    return (
      <Box sx={{ border: "2px solid white" }} {...props}>
        {props.children}
      </Box>
    );
  }

  return (
    <Box component={Container} sx={{ ...wrapperStyle?.sx }}>
      {Title && (
        <Box>
          {Title && (
            <Typography className="section-title" variant="h2" align="center">
              {Title}
            </Typography>
          )}
        </Box>
      )}
      <Tabs
        value={value}
        onChange={handleChange}
        variant={isXsmall ? "scrollable" : "fullWidth"}
        indicatorColor={indicatorColor}
        TabIndicatorProps={TabIndicatorProps}
        textColor="inherit"
        scrollButtons
        // allowScrollButtonsMobile
        centered={isXsmall ? false : true}
        aria-label="Housing style tabs"
        visibleScrollbar={visibleScrollbar}
      >
        {TabItem?.map((tab, index) => {
          return (
            <Tab key={`tabItem${tab.Name}`} label={tab.Name} wrapped sx={{ color: "white" }} />
          );
        })}
      </Tabs>

      <SwipeableViews index={value} onChangeIndex={handleChange}>
        {TabItem?.map((tab, index) => {
          return (
            <CustomTabPanel
              key={`tabPanel${tab.Name}`}
              value={value}
              index={index}
              className={"tm-panel"}
              //TODO abstract sx props
              sx={{
                padding: { xs: "1rem 2rem 2rem", md: "4em 2rem 2rem" },
                ...tab?.options?.wrapperStyle?.sx,
              }}
            >
              {/* TODO abstract this */}
              <Grid container>
                <Grid item xs={12} sm={6}>
                  {parseWithLinks(tab?.TabPanel, { alignText: "left" })}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {tab?.Media?.data?.map((media, index) => {
                    //TODO Use a gallery component if there are more than one image
                    return (
                      <SimpleResponsivePicture key={media.attributes} imageObj={media.attributes} />
                    );
                  })}
                </Grid>
              </Grid>
            </CustomTabPanel>
          );
        })}
      </SwipeableViews>
    </Box>
  );
}
