import React from "react";
import styled from "@emotion/styled";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";

import LoadingProgress from "../components/LoadingProgress";
import DynamicComponentLoader from "../components/DynamicComponent";

export default function About({ handleBookATourClick, isLoading, ...props }) {
  const { PageContent: Content } = props?.data;

  return (
    <Wrapper id="about">
      <Helmet>
        <title itemProp="name" lang="en">
          About
        </title>
      </Helmet>
      <Container disableGutters align="center" maxWidth={false}>
        <LoadingProgress isLoading={isLoading} />
        {Content &&
          Content?.map((block, index) => {
            return (
              <DynamicComponentLoader
                key={`${block.__component}${index}`}
                theme={props?.theme}
                isSmall={props?.isSmall}
                {...block}
              />
            );
          })}
      </Container>
    </Wrapper>
  );
}
const Wrapper = styled("div")``;
