import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography, Container, Link } from "@mui/material";

export default function BuilderContacts(props) {
  const Builders = {};

  props?.showHomeData?.forEach(item => {
    const home_builder = item.attributes.home_builder;
    if (home_builder && home_builder.data) {
      const builderId = home_builder.data.id;
      Builders[builderId] = home_builder.data.attributes;
    }
  });

  // Check if "Sunview Custom Homes" is already in Builders
  const sunviewExists = Object.values(Builders).some(
    builder => builder.Name === "Sunview Custom Homes"
  );

  // If Sunview doesn't exist, add it
  if (!sunviewExists) {
    Builders[6] = {
      Name: "Sunview Custom Homes",
      phone: "403-860-1005",
      url: "https://sunviewcustomhomes.ca/clearwater-park-chestermere-coming-soon/",
    };
  }

  // console.log({ Builders, showHomeData: props?.showHomeData });

  // Convert Builders object to array
  const BuildersArray = Object.values(Builders);

  return (
    <Container disableGutters>
      <Grid container rowSpacing={10} mt={"8rem"} mb={"8rem"} justifyContent={"space-evenly"}>
        <Grid item xs={12} align="center">
          <Typography variant="h2" style={{ marginBottom: 0 }}>
            Get in Touch With Our Builders
          </Typography>
        </Grid>
        {BuildersArray &&
          BuildersArray?.sort((a, b) => {
            if (a.Name === "Centron Group") return -1;
            if (b.Name === "Centron Group") return 1;
            return 0;
          })?.map((builder, index) => {
            return (
              <Grid item xs={12} md={4} align="center" key={builder.Name}>
                <Typography variant="h4" width="100%" paragraph>
                  <Link href={builder.url} target="Clearwater" underline={"none"}>
                    {builder.Name}
                  </Link>
                </Typography>
                {builder?.Contact && (
                  <Typography variant="body1" width="100%" sx={{ fontWeight: 800 }}>
                    {builder.Contact}
                  </Typography>
                )}
                {builder?.email && (
                  <Typography variant="body1" width="100%">
                    <Link href={`mailto:${builder.email}`} underline={"none"}>
                      {builder.email}
                    </Link>
                  </Typography>
                )}
                {builder?.phone && (
                  <Typography variant="body1" width="100%">
                    <Link href={`tel:${builder.phone}`} underline={"none"}>
                      {builder.phone}
                    </Link>
                  </Typography>
                )}
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
}
