import React from "react";

import { Typography, Link, Box, Container } from "@mui/material";

import { ReactComponent as FooterLogo } from "../assets/FooterLogo.svg";
import SocialIcons from "./SocialIcons";

export default function Footer({ isSmall, isXsmall, ...props }) {
  const today = new Date();

  // TODO Fix the layout on phone/touch

  return (
    <Container maxWidth={"xl"} disableGutters sx={{ borderTop: "2px solid #fff" }}>
      <Box
        component={"footer"}
        bgcolor="primary.dark"
        p="4rem 2rem"
        sx={{
          display: "grid",
          gridTemplateAreas: {
            xs: `"hours"
            "location"`,
            sm: `"hours location"`,
            md: `"logo hours location"
            "social . ."`,
            lg: '"logo social hours location"',
          },

          gridColumnGap: { xs: "unset", sm: "3rem", md: "1rem" },
        }}
      >
        {!isXsmall && !isSmall && (
          <Box sx={{ gridArea: "logo", alignSelf: { lg: "center" } }}>
            <a href="/" aria-label="Clearwater Home">
              <FooterLogo style={{ width: "90%", height: "auto" }} />
            </a>
          </Box>
        )}
        {!isXsmall && !isSmall && (
          <Box
            sx={{ gridArea: { md: "logo", lg: "social" }, alignSelf: { md: "end", lg: "start" } }}
          >
            <Typography
              sx={{ textTransform: "uppercase" }}
              variant="h6"
              color="common.white"
              paragraph
            >
              Follow Us On
            </Typography>

            <SocialIcons
              instagram="https://instagram.com"
              facebook="https://facebook.com"
              iconcolor="#fff"
              style={{ justifyContent: "flex-start" }}
            />
          </Box>
        )}
        <Box sx={{ gridArea: "hours" }}>
          <Typography
            sx={{ textTransform: "uppercase" }}
            variant="h6"
            color="common.white"
            paragraph
          >
            Showhome Hours
          </Typography>
          <Typography
            sx={{
              textTransform: "uppercase",
              // whiteSpaceCollapse: "preserve-breaks",
              whiteSpace: "pre-line",
            }}
            color="common.white"
          >
            {`Underway and Coming Soon!`}
          </Typography>
        </Box>
        <Box sx={{ gridArea: "location", marginTop: { xs: "2rem", sm: "initial" } }}>
          <Typography
            sx={{ textTransform: "uppercase" }}
            variant="h6"
            color="common.white"
            paragraph
          >
            Visit Clearwater Park
          </Typography>
          <Typography
            sx={{
              textTransform: "uppercase",
              whiteSpaceCollapse: "preserve-breaks",
              whiteSpace: "pre",
            }}
            color="common.white"
            paragraph
          >
            {`East of Chestermere 
Along township rd 243, directly 
off of the trans-canada hwy 1`}
          </Typography>
          <Link
            href="https://www.google.com/maps/dir//51.0491971,-113.7953366/@51.045268,-113.8398292,6180m/data=!3m1!1e3?entry=ttu"
            target="Clearwater"
            underline="none"
            sx={{ textTransform: "uppercase", color: "#fff", fontWeight: 800 }}
          >
            Get Directions >
          </Link>
        </Box>
      </Box>
      <Box bgcolor={"rgba(10,40,60,1)"} align="center" sx={{ padding: { xs: "1rem", md: "1rem" } }}>
        <Typography
          color="common.white"
          sx={{ fontSize: { xs: 10, md: 10 }, lineHeight: { xs: 1.5, md: "initial" } }}
        >
          {`Illustrations are artist’s concept. Due to ongoing project development features and
          specifications are subject to change without notice. Centron Group ©${today.getFullYear()} 
          Clearwater Park. All rights reserved. Privacy Policy.`}
        </Typography>
      </Box>
    </Container>
  );
}
