import React from "react";
import { Grid, CardContent, Typography } from "@mui/material";

function PropertyTitle({ data: title, ...props }) {
  return (
    <CardContent
      sx={{
        paddingLeft: 0,
        paddingRight: 0,
      }}
      component={Grid}
      container
      alignItems={"flex-end"}
    >
      <Typography gutterBottom variant="h4" component="div">
        {title}
      </Typography>
    </CardContent>
  );
}

export default PropertyTitle;
