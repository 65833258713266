import React from "react";
import { Container } from "@mui/material";

import NewsSection from "./NewsSection";
import { Outlet } from "react-router";

export default function NewsListingComponent({
  handleNewsClick,
  isSmall,
  isXsmall,
  isTouch,
  ...props
}) {
  return (
    <Container maxWidth="lg" disableGutters={isSmall || isXsmall ? false : true}>
      {/* <Typography variant="h2">{props?.Title}</Typography> */}
      {props?.NewsItems && (
        <NewsSection handleNewsClick={handleNewsClick} newsData={props?.NewsItems} {...props} />
      )}
      <Outlet />
    </Container>
  );
}
