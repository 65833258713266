import React, { useState, useEffect } from "react";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { useTheme } from "@mui/material";
import { Helmet } from "react-helmet";

import DynamicComponentLoader from "../components/DynamicComponent";
import { getStrapiContent } from "../Functions";
export default function ForLease({ isLoading, pageTitle, pageId, ...props }) {
  // const loaderData = useLoaderData();
  const loaderData = props?.data;
  const theme = useTheme();
  const [PropertiesData, setPropertiesData] = useState();
  const { PageContent: Content } = loaderData || {};

  // TODO use something like this to pass only relevant data to component
  function findPageContentByComponent(object, component) {
    if (object.data && Array.isArray(object.data.PageContent)) {
      return object.data.PageContent.find(page => page.__component === component);
    }
    return null;
  }
  useEffect(() => {
    getStrapiContent("/api/properties", setPropertiesData);
  }, []);

  return (
    <>
      <Helmet>
        <title itemProp="name" lang="en">
          {pageTitle}
        </title>
      </Helmet>

      {Content &&
        Content?.map((block, index) => {
          const { data, ...restOfProps } = props;
          const componentData = { ...block };

          return (
            <DynamicComponentLoader
              key={`${block.Copy}${index}`}
              theme={theme}
              {...componentData}
              {...restOfProps}
              propertiesData={PropertiesData}
            />
          );
        })}
      {props?.children}
    </>
  );
}
