import React from "react";
import styled from "@emotion/styled";

import { NavLink } from "react-router-dom";

const NavItemText = styled("span")`
  text-transform: none;
`;

//Styles to set the shape of the button set in nav.js
function NavItem({ href, style, handleOnClick, children, ...props }) {
  return (
    <NavLink to={href} style={style} onClick={handleOnClick} {...props}>
      <NavItemText>{children}</NavItemText>
    </NavLink>
  );
}

export default NavItem;
