import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Container, Box } from "@mui/material";
// import purify from "dompurify";
import { parseWithLinks } from "../Functions";
export default function Embed(props) {
  // Can't use purify.sanitize since it removes the iframe
  // Need to rething the CMS implementation to accept only the url
  // or simply accept this. Risk of injection attacks.

  /*
  //TODO This will only work correctly if controls are off in the video attributes.
  // Trap for the attribute controls.
  
   const [videoTarget, setvideoTarget] = useState();
  useEffect(() => {
    //TODO The element id needs to be manually set here. Might make some sense to trap for a nodeName="VIDEO"
    setvideoTarget(document.getElementById("video") || null);
  }, []);
  useEffect(() => {
    if (videoTarget) {
      videoTarget.addEventListener("click", () => {
        if (videoTarget.paused) {
          videoTarget
            .play()
            .then(() => {})
            .catch(error => {
              console.error("Failed to play the video:", error);
            });
        } else {
          videoTarget.pause();
        }
      });
    }
  }, [videoTarget]);
*/

  const { embedStyle } = props?.options;
  const { style: wrapperStylesStyle } = props?.options?.wrapperStyle;
  const wrapperProps = { ...props?.options?.wrapperStyle };
  const title = props?.options?.title || null;

  delete wrapperProps?.style;
  return (
    <Wrapper
      WrapperStyle={wrapperStylesStyle}
      sx={wrapperStylesStyle}
      {...wrapperProps}
      disableGutters={wrapperProps?.disableGutters || true}
      maxWidth={wrapperProps?.maxWidth || false}
    >
      {title && parseWithLinks(title)}
      <EmbedWrapper
        sx={{ ...embedStyle }}
        dangerouslySetInnerHTML={{ __html: props?.EmbedCode }}
        // dangerouslySetInnerHTML={{ __html: purify.sanitize(props?.EmbedCode) }}
      />
    </Wrapper>
  );
}

const Wrapper = styled(({ WrapperStyle, ...props }) => <Container {...props} />)`
  ${props => {
    return { ...props.WrapperStyle };
  }}
`;

const EmbedWrapper = styled(Box)`
  ${props => {
    return { ...props.embedStyle };
  }}
`;
