import React from "react";
import styled from "@emotion/styled";
import { Container } from "@mui/material";

import { removeCommentAttributes, parseWithLinks } from "../Functions";

export default function Description({ Copy, ...props }) {
  const wrapperStyle = removeCommentAttributes(props?.options?.wrapperStyle) || {};

  delete props.propertiesData;
  delete props.isXsmall;
  return (
    <Wrapper
      {...props}
      {...wrapperStyle}
      disableGutters={wrapperStyle?.disableGutters || true}
      maxWidth={wrapperStyle?.maxWidth || "lg"}
      align={wrapperStyle?.align || "center"}
    >
      {parseWithLinks(Copy)}
      {props?.children}
    </Wrapper>
  );
}

const Wrapper = styled(({ handlePropertyClick, PropertyData, isSmall, Properties, ...props }) => (
  <Container {...props} />
))`
  .MuiTypography-h2 {
    white-space-collapse: preserve-breaks;
  }
  .raw-html-embed {
    margin-top: 5rem;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;

    .column {
      display: flex;
      justify-content: space-evenly;
      align-content: center;
      flex-direction: column;
      max-width: 420px;
      min-width: 363px;
      padding: 0;

      ${props => props.theme.breakpoints.up("md")} {
        padding: 1.5rem;
      }
    }
  }
  ${props => {
    return { ...props?.options?.textBlockStyle?.sx };
  }}
`;
