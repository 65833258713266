import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Zoom from "@mui/material/Zoom";

import useScrollTrigger from "@mui/material/useScrollTrigger";

// Implementation

/* <ScrollTop {...props}>
<Fab color="secondary" size="small" aria-label="scroll back to top">
  <KeyboardArrowUpIcon />
</Fab>
</ScrollTop> */

function ScrollTop(props) {
  const { children } = props;
  let window = undefined;

  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  const trigger = useScrollTrigger({
    target: window ? document.window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = event => {
    window = global || window;
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // const anchor = event.target.ownerDocument || document.querySelector("#top-nav");
  };

  return (
    <Zoom in={trigger}>
      <Wrapper onClick={handleClick} role="presentation">
        {children}
      </Wrapper>
    </Zoom>
  );
}
const Wrapper = styled.div`
  position: fixed;
  bottom: 3rem;
  right: 1rem;
  ${props => props.theme.breakpoints.up("sm")} {
    bottom: 4rem;
  }
`;

ScrollTop.propTypes = { children: PropTypes.object };

export default ScrollTop;
