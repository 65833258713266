import React from "react";
import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export default function StrapiGrid({ options = {}, ...props }) {
  const {
    justifyContent = "center",
    alignContent = "center",
    alignItems = "stretch",
    columns = 12,
    columnSpacing = null,
    columnGap = null,
    rowSpacing = "",
    spacing = "",
    wrap = "wrap",
    ...layoutOptions
  } = options;

  const direction = layoutOptions?.sx?.hasOwnProperty("flexDirection")
    ? layoutOptions?.sx?.flexDirection
    : "row";

  return (
    <Wrapper
      container
      justifyContent={justifyContent}
      alignContent={alignContent}
      alignItems={alignItems}
      columnSpacing={columnSpacing}
      columnGap={columnGap}
      rowSpacing={rowSpacing}
      spacing={spacing}
      direction={direction}
      columns={columns}
      wrap={wrap}
      id={props?.id}
      {...layoutOptions}
      className={props?.className ? props?.className : "StrapiGrid"}
    >
      {props.children}
    </Wrapper>
  );
}

const Wrapper = styled(({ imageSize, useButtonForLink, ...props }) => <Grid {...props} />)``;
