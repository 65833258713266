import React from "react";
import styled from "@emotion/styled";
import { Box, Container, Grid, Typography } from "@mui/material";

import StrapiGrid from "./StrapiGrid";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import CraftsmanIcon from "../assets/CraftsmanIcon.svg";
import PrairieIcon from "../assets/PrairieIcon.svg";
import CoastalIcon from "../assets/CoastalIcon.svg";
import FarmhouseIcon from "../assets/FarmhouseIcon.svg";
import MountainIcon from "../assets/MountainIcon.svg";
import Wave from "../assets/Wave.svg";

export default function StyleIcons({ isSmall, isXsmall, ...props }) {
  const images = [
    {
      description: `Craftsman &
    Modern Craftsman`,
      original: CraftsmanIcon,
    },
    { description: `Mountain Modern`, original: MountainIcon },
    {
      description: `Farmhouse &
    Modern farmhouse`,
      original: FarmhouseIcon,
    },
    { description: `Coastal`, original: CoastalIcon },
    {
      description: `Prairie &
    Modern Prairie`,
      original: PrairieIcon,
    },
  ];

  const itemRenderer = item => {
    const isNoCaption = item?.description === "No Caption";
    return (
      <div>
        <HouseIcons src={item.original} alt={item.original} />
        {!isNoCaption && (
          <Caption component="div" variant="button">
            {item.description}
          </Caption>
        )}
      </div>
    );
  };

  const options = {
    settings: {
      showThumbnails: false,
      // thumbnailPosition: isPhone ? "bottom" : "left",
      showBullets: false,
      showIndex: false,
      showPlayButton: false,
      showFullscreenButton: false,
      autoPlay: true,
      renderItem: itemRenderer,
      showNav: isSmall ? false : true,
      slideInterval: 3000,
      slideDuration: 200,
      // onSlide: handleOnSlide,
      //   renderLeftNav: (onClick, disabled) => {
      //     return <LeftArrow onClick={onClick} disabled={disabled} />;
      //   },
      //   renderRightNav: (onClick, disabled) => {
      //     return <RightArrow onClick={onClick} disabled={disabled} />;
      //   },
    },
  };

  return (
    <Wrapper sx={{ ...props?.options?.wrapperStyle }}>
      {isXsmall && <ImageGallery items={images} {...options.settings} {...props} />}
      {!isXsmall && (
        <StrapiGrid options={props?.options?.layout}>
          {images.map((item, index, array) => {
            const isNoCaption = item?.description === "No Caption";
            return (
              <Grid
                key={item.original}
                item
                container
                md={2.3}
                sm={2.37}
                xs={12}
                direction="column"
                justifyContent={"flex-start"}
                alignContent={"center"}
                alignItems={"center"}
                sx={{ mt: { xs: "4rem", md: "2rem" } }}
              >
                <HouseIcons src={item.original} alt={item.original} />
                {!isNoCaption && (
                  <Caption variant="button" align="center">
                    {item.description}
                  </Caption>
                )}
              </Grid>
            );
          })}
        </StrapiGrid>
      )}
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  min-height: 385px;
  background: url(${Wave}) bottom right no-repeat;
  background-size: cover;
  ${props => props.theme.breakpoints.up("sm")} {
    background: url(${Wave}) bottom left no-repeat;
    background-size: cover;
  }
`;

const HouseIcons = styled("img")`
  max-width: 195px;
  height: 195px;
  //   height: auto;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    max-width: 130px;
    height: 130px;
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 195px;
    height: 195px;
  }
`;

const Caption = styled(Typography)`
  white-space-collapse: preserve-breaks;
  text-transform: uppercase;
  z-index: 99;
  color: white;
  text-align: center;
  padding-top: 3rem;
  height: fit-content;
  min-height: 49px;
`;
