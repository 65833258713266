/**
 * Removes comments from the input object
 *
 * @export
 * @param {*} inputObject
 * @return {*}
 */
export default function removeCommentAttributes(inputObject) {
  for (const key in inputObject) {
    if (inputObject.hasOwnProperty(key) && key === "comment") {
      delete inputObject[key];
    }
  }
  return inputObject;
}
