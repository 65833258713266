import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Grid, Box } from "@mui/material";
import SimpleResponsivePicture from "./SimpleResponsivePicture";
export default function ImageGroup({ Copy, ...props }) {
  // TODO implement options from CMS

  // {
  //     "layout": {},
  //     "imageStyle": {},
  //     "wrapperStyle": {},
  //     "textBlockStyle": {}
  //   }

  const { justifyContent, alignContent, columnGap, wrap, ...layoutOptions } =
    props?.options?.layout || false;

  const wrapperStyle = props?.options?.wrapperStyle || {};
  const imageStyle = props?.options?.imageStyle || {};
  const textBlockStyle = props?.options?.textBlockStyle || {};
  return (
    <Grid
      container
      justifyContent={justifyContent || "center"}
      alignContent={alignContent || "flex-start"}
      columnGap={columnGap || "5%"}
      rowGap={"2rem"}
      wrap={wrap || "wrap"}
      columns={props?.ImageGroupImage?.length} //TODO This should have a sensible max value
      {...layoutOptions}
    >
      <Box dangerouslySetInnerHTML={{ __html: Copy }} sx={{ ...textBlockStyle }} />
      {props?.ImageGroupImage &&
        props?.ImageGroupImage?.map((member, index) => {
          return (
            <Grid key={member?.imageAsset.data.attributes.url} item>
              <Grid container direction={"column"}>
                {member?.name && (
                  <Grid
                    item
                    align="left"
                    dangerouslySetInnerHTML={{ __html: `<h4>${member?.name}</>` }}
                  />
                )}
                {member.link && (
                  <LinkWrapper href={member.link} target="Clearwater">
                    <SimpleResponsivePicture
                      imageObj={
                        member?.imageAsset?.data?.attributes ||
                        member?.imageAsset?.data?.attributes?.url
                      }
                      sx={{ ...imageStyle }}
                    />
                  </LinkWrapper>
                )}
                {!member.link && (
                  <SimpleResponsivePicture
                    imageObj={
                      member?.imageAsset?.data?.attributes ||
                      member?.imageAsset?.data?.attributes?.url
                    }
                    sx={{ ...imageStyle }}
                  />
                )}
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
}
const LinkWrapper = styled("a")`
  color: green;
  &:after {
    content: unset !important;
  }
`;
