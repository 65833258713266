import React from "react";
import { RouterProvider } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import router from "./router";

import "./App.css";

function App(props) {
  return <RouterProvider router={router} fallbackElement={<CircularProgress />} />;
}

export default App;
